import axios from 'axios';
import { HttpStatus } from 'constants/http-status';
import { isAxiosError } from 'utils/axios';

type WidgetHtml = string;

type WedgetsResponse = {
  preview_badges?: {
    [productId: number]: WidgetHtml;
  };
};

/**
 * Get reviews for products from judge me cache server.
 * Available only on client side.
 * @SEE : https://help.judge.me/en/articles/8409211-retrieving-cached-widgets-from-the-judge-me-cache-server
 */
export const getJudgeMeRatings = async ({
  externalIds,
}: {
  externalIds: Array<string | number>;
}): Promise<WedgetsResponse['preview_badges'] | undefined> => {
  try {
    const response = await axios.get<WedgetsResponse>(
      `https://cache.judge.me/widgets/shopify/${process.env.SHOPIFY_DOMAIN}`,
      {
        params: {
          public_token: process.env.JUDGE_ME_PUBLIC_TOKEN,
          preview_badge_product_ids: externalIds.toString(),
        },
        errorHandling: {
          statusesToOmit: [HttpStatus.NOT_FOUND, HttpStatus.TOO_MANY_REQUESTS],
        },
      }
    );
    if (!response.data) throw new Error('Details not found');

    return response.data.preview_badges;
  } catch (e) {
    if (isAxiosError(e)) {
      console.error(`getJudgeMeRatings`, e);
    }
    return undefined;
  }
};
